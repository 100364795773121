import { Box, Card, Flex, Group, Loader, Popover, Stack, clsx, createStyles } from '@mantine/core'
import { IconCloudDownload } from '@tabler/icons'
import React, { MouseEventHandler, useState } from 'react'

import { TrackingPageSource } from '~/analytics/CreatorEvent'
import { InlineAuthView } from '~/components/auth/InlineAuthView'
import { ThatchLink } from '~/components/shared/ThatchLink'
import { Typography } from '~/components/shared/text/Typography'
import { useCurrentAuthState } from '~/context'
import { BoardSummaryType } from '~/endpoints/model'
import { useScreenSize } from '~/hooks'
import thatchConfig from '~/thatch-config'
import { calculateDiscountedPrice } from '~/utils/helper'
import { priceFormatter } from '~/utils/string'

import { BoardAuthor } from '../board/BoardAuthor'
import { BoardAuthorWithoutContext } from '../board/BoardAuthorWithoutContext'
import { BoardShareButton } from '../board/BoardShareButton'
import { ReviewCardSummary } from '../reviews/ReviewSummary'
import { SvgIcon, SvgIconType } from '../shared/image/SvgIcon'
import { notify } from '../shared/notify'
import { GuideCardImage } from './GuideCardImage'
import GuideTypeTag from './GuideTypeTag'

const useStyle = createStyles(
  (
    _,
    {
      imageWidth,
      imageHeight,
      withBorders,
    }: { withBorders?: boolean; imageWidth: number; imageHeight: number }
  ) => ({
    cardContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      gap: 24,
      width: '100%',
      [withBorders ? 'border' : 'borderBottom']: '1px solid rgba(0, 0, 0, 0.10)',
      [withBorders ? 'borderRadius' : '']: 8,
    },
    cardImageContainer: {
      position: 'relative',
      height: imageHeight,
      minWidth: imageWidth,
      width: imageWidth,
      margin: '0px !important',
    },
    textInfoContainer: {
      margin: '0px !important',
    },
    infoContainer: {
      margin: '0px !important',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    originalValue: {
      textDecoration: 'line-through',
      textDecorationLine: 'strikethrough',
      textDecorationThickness: 1,
    },
    text: {
      textOverflow: 'ellipsis',
      lineHeight: '110%',
    },
  })
)

const Icon = ({
  onClick,
  type,
  className,
}: {
  onClick?: MouseEventHandler<HTMLDivElement>
  type: SvgIconType,
  className?: string,
}) => (
  <Box
    onClick={onClick}
    sx={{ cursor: 'pointer', height: 24 }}
    className={className}
  >
    <SvgIcon
      type={type}
      width={24}
      height={24}
    />
  </Box>
)

interface GuideInfoCardProps {
  data: BoardSummaryType
  isSignedIn: boolean
  // onGuideUpdate?: (guide: BoardSummaryType) => void | Promise<void>
  onModalClose: () => void
  onClickAnalyticsCallback?: (board: BoardSummaryType) => void
  trackingSource: TrackingPageSource
  withoutContext?: boolean
  withBorders?: boolean
  styleOnHover?: boolean
  showMenu?: boolean
  isLoading?: boolean
  onDuplicate?: (token: string) => void
  onToggleFav?: (token: string) => void
  onDelete?: (token: string) => void
  onUnsave?: (token: string, isSaved: boolean) => void
}
export const GuideInfoCard: React.FC<GuideInfoCardProps> = ({
  data,
  isSignedIn,
  showMenu,
  trackingSource,
  withoutContext,
  withBorders,
  styleOnHover,
  isLoading,
  onModalClose,
  onDuplicate,
  onToggleFav,
  onDelete,
  onUnsave,
  onClickAnalyticsCallback,
}) => {
  const { email } = useCurrentAuthState()
  const { isMobileScreen } = useScreenSize()
  const [showInlineAuth, setShowInlineAuth] = useState(false)
  const [menuOpened, setMenuOpened] = useState(false)

  const imageWidth = isMobileScreen ? 120 : 170
  const imageHeight = isMobileScreen ? 160 : 224

  const { classes } = useStyle({ imageHeight, imageWidth, withBorders })

  const tags = [...(data.designedFor || []), ...(data.themes || [])]

  const stopIconClickPropagation = (e: React.MouseEvent<HTMLElement>) => e.stopPropagation()

  const { discount, price } = data
  const formattedPrice = !data.published ? 'Private' : priceFormatter(price)
  const discountedPrice =
    discount && price ? calculateDiscountedPrice(price, discount.amount).formatted : formattedPrice

  const onUserSetupSuccess = async (_currentUserUid: string) => {
    onUnsave?.(data.token, data.state.saved)
    setShowInlineAuth(false)
  }

  const handleSaveClick = async (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation()
    e.preventDefault()
    if (!email) {
      setShowInlineAuth(true)
    } else {
      onUnsave?.(data.token, data.state.saved)
    }
  }
  if (discount) console.log(data)

  const renderPriceTag = () => {
    if (discount) {
      return (
        <Group
          position="left"
          spacing="xs"
        >
          <Typography
            variant="button_small"
            className={classes.text}
          >
            {discountedPrice}
          </Typography>
          <Typography
            variant="caption"
            color="appPlaceholder.0"
            className={clsx(classes.text, classes.originalValue)}
          >
            {formattedPrice}
          </Typography>
          <Typography
            variant="caption"
            color="appGreen.1"
            className={classes.text}
          >
            {discount.amount}% off
          </Typography>
        </Group>
      )
    }

    return (
      <Typography
        variant="button_small"
        className={classes.text}
      >
        {formattedPrice}
      </Typography>
    )
  }

  const renderSaveIcon = () => {
    if (!isSignedIn || isLoading) {
      return (
        <>
          <Icon
            type={SvgIconType.SAVE_FILLED}
            className="cursor-default"
          />
          <Typography variant="caption">{data.saveCount}</Typography>
        </>
      )
    }

    return (
      <>
        {data.state.saved ? (
          <Icon
            type={SvgIconType.SAVE_FILLED}
            onClick={e => handleSaveClick(e)}
          />
        ) : (
          <Icon
            type={SvgIconType.SAVE_OUTLINE}
            onClick={e => handleSaveClick(e)}
          />
        )}
        <Typography variant="caption">{data.saveCount}</Typography>
      </>
    )
  }

  const MenuItem = ({
    type,
    label,
    onClick,
  }: {
    type: SvgIconType
    label: string
    onClick?: () => void
  }) => (
    <Flex
      gap={8}
      sx={{ cursor: 'pointer' }}
      onClick={onClick}
    >
      <SvgIcon
        width={24}
        height={24}
        type={type}
      />
      <Typography variant="button_medium">{label}</Typography>
    </Flex>
  )

  const CardFooter = () => {
    return (
      <Flex
        direction="row"
        justify="space-between"
        align="center"
      >
        <Stack
          sx={{ flexDirection: 'row' }}
          align="center"
          spacing={4}
        >
          {renderPriceTag()}
        </Stack>

        <Flex
          align="center"
          gap={20}
        >
          <ReviewCardSummary
            rating={data.rating}
            reviews={data.reviews}
          />
          {!withoutContext && (
            <Stack
              sx={{ flexDirection: 'row' }}
              align="center"
              spacing={4}
              onClick={stopIconClickPropagation}
            >
              {renderSaveIcon()}
            </Stack>
          )}

          {showMenu && isLoading && (
            <Flex align="center">
              <Loader
                color="black"
                size={18}
              />
            </Flex>
          )}
          {showMenu && !isLoading && (
            <Popover
              position="right-end"
              opened={menuOpened}
              onChange={setMenuOpened}
            >
              <Group spacing={12}>
                <Popover.Target>
                  <Flex
                    onClick={() => setMenuOpened(!menuOpened)}
                    sx={{ cursor: 'pointer' }}
                  >
                    <SvgIcon type={SvgIconType.MENU_DOTS_BOLD_ICON} />
                  </Flex>
                </Popover.Target>
              </Group>

              <Popover.Dropdown p={16}>
                <Flex
                  direction="column"
                  gap={16}
                  sx={{ cursor: 'pointer' }}
                >
                  <BoardShareButton
                    sx={{ padding: 0, margin: 0 }}
                    boardToken={data.token}
                    onCopy={() => notify(false, 'Guide link copied to clipboard', ' ')}
                    sharedFrom="m_g"
                    sharedTo="c_l"
                    isShareSheet="n"
                  >
                    <MenuItem
                      label="Share"
                      type={SvgIconType.SHARE_MENU_ICON}
                    />
                  </BoardShareButton>

                  {data.state.mine && (
                    <>
                      <MenuItem
                        label="Delete"
                        type={SvgIconType.DELETE_MENU_ICON}
                        onClick={() => onDelete?.(data.token)}
                      />
                      <MenuItem
                        label="Duplicate"
                        type={SvgIconType.DUPLICATE_MENU_ICON}
                        onClick={() => onDuplicate?.(data.token)}
                      />
                    </>
                  )}

                  {data.state.saved && !data.state.payment?.paid && (
                    <MenuItem
                      label="Unsave"
                      type={SvgIconType.SAVE_FILLED}
                      onClick={() => onUnsave?.(data.token, !data.state.saved)}
                    />
                  )}

                  <MenuItem
                    label="Favorite"
                    type={SvgIconType.FAVORITE_ON_MENU_ICON}
                    onClick={() => onToggleFav?.(data.token)}
                  />

                  {(data.state.mine ||
                    (data.state.saved && (!data.price || !!data.state.payment?.paid))) && (
                      <ThatchLink
                        to={`${thatchConfig.apiEndpoint}/takeout/download/${data.token}`}
                        isOpenInNewWindow
                        underline={false}
                        onClick={() => setMenuOpened(false)}
                      >
                        <Flex gap={8}>
                          <IconCloudDownload
                            width={24}
                            height={24}
                          />
                          <Typography variant="button_medium">Download</Typography>
                        </Flex>
                      </ThatchLink>
                    )}
                </Flex>
              </Popover.Dropdown>
            </Popover>
          )}
        </Flex>
      </Flex>
    )
  }

  const url = `/guide/${data.token}/view`
  const onClick: MouseEventHandler<HTMLAnchorElement> = _e => {
    onClickAnalyticsCallback?.(data)
    onModalClose?.()
  }

  return (
    <>
      <Card
        sx={{ ':has(a:hover)': styleOnHover ? { backgroundColor: '#F3F2EF' } : {} }}
        px={withBorders ? 16 : 0}
        py={withBorders ? 16 : 32}
        className={classes.cardContainer}
        radius={0}
      >
        <Flex
          direction="column"
          gap={12}
          w="100%"
        >
          <Flex
            gap={isMobileScreen ? 13 : 24}
            justify="space-between"
          >
            <Card.Section
              className={classes.infoContainer}
              sx={{ width: '100%' }}
            >
              <Stack
                className={classes.textInfoContainer}
                spacing={12}
              >
                <Flex
                  gap={12}
                  direction="column"
                >
                  {withoutContext ? (
                    <BoardAuthorWithoutContext
                      author={data.author}
                      desktopPhotoSize={30}
                      mobilePhotoSize={30}
                      photoGap={12}
                      titleVariant="captionSerifItalic"
                      tagVariant="sm_tag"
                      containerStyle={{ margin: 0 }}
                      click_source={trackingSource}
                    />
                  ) : (
                    <BoardAuthor
                      author={data.author}
                      desktopPhotoSize={30}
                      mobilePhotoSize={30}
                      photoGap={12}
                      titleVariant="captionSerifItalic"
                      tagVariant="sm_tag"
                      containerStyle={{ margin: 0 }}
                      trackingSource={trackingSource}
                    />
                  )}

                  <ThatchLink
                    to={url}
                    isOpenInNewWindow
                    underline={false}
                    onClick={onClick}
                  >
                    <Typography
                      variant={isMobileScreen ? 'title2' : 'title'}
                      lineClamp={isMobileScreen ? 2 : 1}
                      className={classes.text}
                      sx={{ letterSpacing: '-0.096px' }}
                    >
                      {data.name}
                    </Typography>
                  </ThatchLink>
                </Flex>

                <ThatchLink
                  to={url}
                  isOpenInNewWindow
                  underline={false}
                  onClick={onClick}
                >
                  <Typography
                    variant={isMobileScreen ? 'caption' : 'body3'}
                    color="appPlaceholder.0"
                    lineClamp={isMobileScreen ? 2 : 3}
                    className={classes.text}
                  >
                    {data.description}
                  </Typography>
                </ThatchLink>

                <ThatchLink
                  to={url}
                  isOpenInNewWindow
                  underline={false}
                  onClick={onClick}
                >
                  <Typography
                    variant="tag"
                    lineClamp={1}
                    className={classes.text}
                  >
                    {tags.join('  •  ')}
                  </Typography>
                </ThatchLink>
              </Stack>
              {!isMobileScreen && <CardFooter />}
            </Card.Section>

            <Card.Section className={classes.cardImageContainer}>
              <ThatchLink
                to={url}
                isOpenInNewWindow
                underline={false}
                onClick={onClick}
              >
                <GuideTypeTag type={data.guideType} />
                <GuideCardImage
                  src={data?.coverImage ?? ''}
                  imageWidth={imageWidth}
                  imageHeight={imageHeight}
                />
              </ThatchLink>
            </Card.Section>
          </Flex>
          {isMobileScreen && <CardFooter />}
        </Flex>
      </Card>
      {showInlineAuth && (
        <InlineAuthView
          boardToken={data?.token}
          launchSource="save_guide"
          onClose={() => setShowInlineAuth(false)}
          isOpened={showInlineAuth}
          initLandingView="signup"
          onEmailAuthSuccess={onUserSetupSuccess}
        />
      )}
    </>
  )
}
